import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import styled from 'styled-components/macro';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import PhotoGallery from './components/PhotoGallery';
import Home from './components/Home';
import AboutUsCard from './components/AboutUsCard';
import IconsCard from './components/IconsCard';
import ContactCard from './components/ContactCard';
import BackToTopButton from './components/BackToTopButton';


function App() {
  const [formIsDislayed, setFormIsDislayed] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    let heightToShowFrom = 200;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;

    if (winScroll > heightToShowFrom) {
      !isVisible && setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <AppLayout>
      <Header />
      {formIsDislayed && <ContactForm
        handleClose={() => setFormIsDislayed(false)}
        handleSubmit={() => console.log("send email")}
      />}
      <Home handleClick={() => setFormIsDislayed(true)} />
      <AboutUsCard />
      <IconsCard />
      <PhotoGallery />
      <ContactCard handleClick={() => setFormIsDislayed(true)} />
      {isVisible && <BackToTopButton />}
      <Footer />
    </AppLayout >
  );
}

export default App;

const AppLayout = styled.div`
  --gap: 20px;
  min-height: 100vh;
  background-color: var(--color-background);
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--gap);
  position: relative;
`;
