import { ReactElement } from "react";
import styled, { css } from "styled-components/macro";

type ButtonProps = {
    text: string | ReactElement;
    title?: string;
    handleClick?: () => void;
    textColor?: string;
    backgroundColor?: string;
    callToAction?: boolean;
    underlineOnHover?: boolean;
    selected?: boolean;
    type?: "button" | "submit" | "reset";
    style?: any;
};

export default function Button({
    text,
    handleClick,
    textColor = "var(--color-blue)",
    backgroundColor = "none",
    callToAction,
    underlineOnHover,
    selected,
    title,
    type,
    style
}: ButtonProps) {
    return (
        <StyledButton
            onClick={handleClick}
            title={text.toString()}
            disabled={selected}
            type={type}
            style={style}
            $textColor={textColor}
            $backgroundColor={backgroundColor}
            $callToAction={callToAction}
            $underlineOnHover={underlineOnHover}
        >
            {text}
        </StyledButton>
    )
}

type AnchorProps = {
    id?: string;
    text?: string | ReactElement;
    title?: string;
    href: string;
    textColor?: string;
    backgroundColor?: string;
    callToAction?: boolean;
    underlineOnHover?: boolean;
    selected?: boolean;
    children?: React.ReactNode;
};

export function Anchor({
    id,
    text,
    title,
    textColor = "var(--color-blue)",
    backgroundColor = "none",
    callToAction,
    underlineOnHover,
    selected,
    href,
    children
}: AnchorProps) {
    return (
        <StyledAnchor
            id={id}
            href={href}
            title={text?.toString() ?? title!}
            $textColor={textColor}
            $backgroundColor={backgroundColor}
            $callToAction={callToAction}
            $underlineOnHover={underlineOnHover}
        >
            {text ?? children}
        </StyledAnchor>
    )
}

interface IButton {
    $textColor: string;
    $backgroundColor: string;
    $callToAction?: boolean;
    $underlineOnHover?: boolean;
}

const buttonStyles = css<IButton>`
    background: ${props => props.$backgroundColor};
    color: ${props => props.$textColor};
	border: none;
	font: inherit;
	outline: inherit;
    font-family: Poppins, Arial, sans-serif;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: ${props => props.$callToAction ? "30px 115px" : "10px"};
    font-size: ${props => props.$callToAction ? "clamp(1rem, -2.3149rem + 8.8398vw, 1.5rem)" : "1rem"};
    cursor: pointer;
    text-decoration: none;
    
    &::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
    &:focus, &:focus-visible {
        border: medium none;
        outline: auto;
    }
    &:hover {
        font-weight: 600;
        text-decoration: ${props => props.$underlineOnHover ? 'underline' : 'none'};
    }
    &:disabled, &[disabled]{
        pointer-events: none;
        font-weight: 600;
        &:hover {
            font-weight: 600;
            text-decoration: none;
        }
    }
    @media (max-width: 750px) {
        padding: 10px;
    }
`;

const StyledButton = styled.button`
    ${buttonStyles}
`;

const StyledAnchor = styled.a`
    ${buttonStyles}
`;