import styled from "styled-components/macro";
import { ReactComponent as FedeTommi } from '../media/Fedetommi.opt.svg';
// import Button from "./Button";
import { GeneralText } from "./GeneralText";
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";

export default function Footer() {
    const { t } = useTranslation(namespaces.common);

    return (
        <Wrapper>
            <ContactInformation>
                <Address>Via Rinaldo Veronesi 30</Address>
                <Address>37135 Verona VR, {t("Italia")}</Address>
                <br />
                <Contact>Victor: <Link href="tel:+393280368221">+39&nbsp;328&nbsp;0368221</Link></Contact>
                <Contact>Valentin: <Link href="tel:+393898836147">+39&nbsp;389&nbsp;8836147</Link></Contact>
                <Contact>Email: <Link href="mailto:vevservizisnc@gmail.com">vevservizisnc@gmail.com</Link></Contact>
                <br />
                <GeneralText>V&V SERVIZI SNC P.IVA 04964560231</GeneralText>
                <br />
                <Copyright>© 2023 V&V SERVIZI SNC - <Link href="https://www.fedetommi.eu"><FedeTommi height={20} fill="white" /></Link></Copyright>
            </ContactInformation>
        </Wrapper >
    )
}

const Wrapper = styled.footer`
    grid-column: 1 / -1;
    background: var(--color-blue-gradient);
    color: var(--color-gray-transparent);
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(12, 1fr);
    z-index: 1;
`;
const ContactInformation = styled(GeneralText)`
    padding: 20px;
    grid-column: 1 / -1;
    height: 100%;
`;
const Copyright = styled(GeneralText)`
    color: white;
    display: flex;
    flex-direction: row;
    gap: 6px;
`;
const Address = styled(GeneralText)``;
const Contact = styled(GeneralText)``;
const Link = styled.a`
  color: var(--color-gray-transparent);
`;