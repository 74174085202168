import styled from "styled-components/macro";
import { useTranslation, Trans } from "react-i18next";
import { namespaces } from "../i18n.costants";
import Button from "./Button";
import { Dialog } from "@reach/dialog";
import { ReactComponent as X } from '../media/x.svg';
import { useState } from "react";
import { StyledHeader } from "./GeneralText";

type ContactFormProps = {
    handleClose: () => any;
    handleSubmit: () => any;
};

export default function ContactForm({ handleClose, handleSubmit }: ContactFormProps) {
    const { t } = useTranslation(namespaces.common);
    const [checked, setChecked] = useState<boolean>(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    return (
        <Dialog>
            <Wrapper>
                <FormContainer onSubmit={handleSubmit}>
                    <CloseButton onClick={handleClose}>
                        <X stroke="#728190" />
                    </CloseButton>
                    <StyledHeader>{t("Richiedi un preventivo gratis e senza impegno")}</StyledHeader>
                    <InformationContainer>
                        <Label htmlFor="Nome">{t("Nome")}</Label>
                        <div>
                            <Input type="text" id="Nome" name="Nome" required />
                        </div>
                    </InformationContainer>
                    <InformationContainer>
                        <Label htmlFor="E-mail">{t("E-mail")}</Label>
                        <div>
                            <Input type="email" id="E-mail" name="E-mail" required />
                        </div>
                    </InformationContainer>
                    <InformationContainer>
                        <Label htmlFor="Phone number">{t("Telefono")}</Label>
                        <div>
                            <Input type="text" id="Phone number" name="Phone number" required />
                        </div>
                    </InformationContainer>
                    <InformationContainer>
                        <Label htmlFor="Postal code">{t("CAP")}</Label>
                        <div>
                            <Input type="text" id="Postal code" name="Postal code" required />
                        </div>
                    </InformationContainer>
                    <InformationContainer>
                        <Label htmlFor="Object">{t("Oggetto")}</Label>
                        <div>
                            <Input type="text" id="Object" name="Object" required />
                        </div>
                    </InformationContainer>
                    <MessageContainer>
                        <Label htmlFor="Message">{t("Messaggio")}</Label>
                        <div>
                            <MessageTextarea id="Message" name="Message" rows={6} required />
                        </div>
                    </MessageContainer>
                    <CheckboxContainer>
                        <Input type="checkbox" checked={checked} onChange={handleChange} required />
                        <Label style={{ flex: "1 0 calc(100% - 24px)" }}>
                            <Trans i18nKey="__Accetta_condizioni__">
                                Ho letto e accettato la <InlineAnchor href="#"> Politica di riservatezza </InlineAnchor>
                                e le <InlineAnchor href="#">Condizioni d'uso</InlineAnchor>
                            </Trans>
                        </Label>
                    </CheckboxContainer>
                    <br />

                    <ButtonContainer>
                        <Button
                            type="submit"
                            text={t("Invia")}
                            textColor="white"
                            backgroundColor='var(--color-blue-gradient)'
                            handleClick={handleSubmit}
                        />
                    </ButtonContainer>

                </FormContainer>

            </Wrapper>
        </Dialog>
    );
}

const Wrapper = styled.form`   
    background-color: var(--color-gray-transparent);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    overflow-y: scroll;
`;
const FormContainer = styled.form`
    padding: 20px;
    background-color: white;
    color: var(--color-gray-dark);
    margin: 6em 0;
    height: max-content;
    grid-column: 3/-3;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    position: relative;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: max-content;
    flex-direction: column;
    
    @media (max-width: 950px) {
        grid-column: 2/-2;
    }
`;
const InformationContainer = styled.div`
    @media (max-width: 450px) {
        grid-column: span 2;
    }
`;
const CloseButton = styled.button`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
	outline: inherit;
    cursor: pointer;
    padding: 0;
    margin: 5px;
    background-color: transparent;
    :hover {
        stroke: var(--color-gray-dark);
    }
    &:focus, &:focus-visible {
        border: medium none;
        outline: auto;
    }
`;
const Input = styled.input`
    width: 100%;
    font-family: Poppins, Arial, sans-serif;
    font-size: 1em;

    &[type="checkbox"] {
        line-height: 1em;
        flex: 1 0 20px;
        align-self: flex-start;
        height: 1em;
        margin-top: 2px;
    }   
`;
const Label = styled.label`
    font-family: Poppins, Arial, sans-serif;
    font-size: 1em;
    display: inline;
`;
const MessageTextarea = styled.textarea`
    width: 100%;
    font-family: Poppins, Arial, sans-serif;
    font-size: 1em;
    resize: none;
    grid-column: span 2;
`;
const ButtonContainer = styled.div`
    align-self: end;
    justify-self: end;
    margin-top: 2em;
`;
const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: baseline;
    grid-column: span 2;
`;
const InlineAnchor = styled.a`
    color: var(--color-blue);
`;
const MessageContainer = styled.div`
    grid-column: span 2;
`;