import { styled } from "styled-components/macro"
import { ReactComponent as ChevronUp } from '../media/chevron-up.svg';
import { GeneralText } from "./GeneralText";
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";

export default function BackToTopButton() {
    const { t } = useTranslation(namespaces.common);

    return (
        <Wrapper title="back-to-top" href={`#${t("nav/home")}`}>
            <ChevronUp stroke="var(--color-blue)" />
            {/* <GeneralText>Back to top</GeneralText> */}
        </Wrapper>
    )
}

const Wrapper = styled.a`
    width: 34px;
    height: 34px;
    position: fixed;
    bottom: 1em;
    right: var(--gap);
    z-index: 2;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-light);
    border-radius: 50%;
    box-shadow: 3px 5px 15px rgba(0,0,0,0.2);

    /* 
    width: max-content;
    gap: 5px;
    padding: 0;
    padding-right: 5px;
    border-radius: 2px; */
`;

