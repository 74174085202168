import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";
import styled from 'styled-components/macro';
import Card from '../components/Card';
import { StyledHeader as H2 } from '../components/GeneralText';
import LazyImage, { Image, ImageContainer } from "./ImageContainer";
import washroomBefore from '../media/photos/bagno_b1.jpeg';
import washroomAfter from '../media/photos/bagno_a1.jpeg';
import showerBefore from '../media/photos/doccia_b1.jpeg';
import showerAfter from '../media/photos/doccia_a1.jpeg';
import windowsDoors1 from '../media/photos/infissi_e_porte_1.jpeg';
import windowsDoors2 from '../media/photos/infissi_e_porte_2.jpeg';
import windowsDoors3 from '../media/photos/infissi_e_porte_3.jpeg';
import industry1 from '../media/photos/soluzioni_industriali_cucina_1.jpeg';
import industry2 from '../media/photos/soluzioni_industriali_cucina_2.jpeg';
import industry3 from '../media/photos/soluzioni_industriali_cucina_3.jpeg';
import industry4 from '../media/photos/soluzioni_industriali_cucina_4.jpeg';
import industry5 from '../media/photos/soluzioni_industriali_cucina_5_cropped.jpeg';
import industry6 from '../media/photos/soluzioni_industriali_uffici_1.jpeg';
import industry7 from '../media/photos/soluzioni_industriali_uffici_2.jpeg';
import industry8 from '../media/photos/soluzioni_industriali_uffici_3.jpeg';
import industry9 from '../media/photos/soluzioni_industriali_uffici_4.jpeg';
import industry10 from '../media/photos/soluzioni_industriali_uffici_5.jpeg';
import industry11 from '../media/photos/soluzioni_industriali_uffici_6.jpeg';
import industry12 from '../media/photos/soluzioni_industriali_uffici_7.jpeg';
import drywall2 from '../media/photos/su_misura_cartongesso_2.jpeg';
import drywall3 from '../media/photos/cartongesso_isolamento_1.jpeg';
import drywall4 from '../media/photos/cartongesso_isolamento_2.jpeg';
import drywall5 from '../media/photos/cartongesso_isolamento_3.jpeg';
import drywallOutsideBefore from '../media/photos/cartongesso_esterni_b.jpeg';
import drywallOutsideAfter from '../media/photos/cartongesso_esterni_a.jpeg';
import lights1 from '../media/photos/su_misura_illuminazione_1_cropped.jpeg';
import lights2 from '../media/photos/su_misura_illuminazione_2.jpeg';
import lights3 from '../media/photos/su_misura_illuminazione_3.jpeg';
import lights4 from '../media/photos/su_misura_illuminazione_4.jpeg';
import lights5 from '../media/photos/su_misura_illuminazione_5.jpeg';
import lights6 from '../media/photos/su_misura_illuminazione_6.jpeg';
import spaceRedimensioning1 from '../media/photos/su_misura_spazi_1.jpeg';
import spaceRedimensioning2 from '../media/photos/su_misura_spazi_2.jpeg';
import ac1 from '../media/photos/aria_condizionata_1.jpeg';
import ac2 from '../media/photos/aria_condizionata_2_cropped.jpeg';
import ac3 from '../media/photos/aria_condizionata_3.jpeg';
import ac4 from '../media/photos/aria_condizionata_4.jpeg';
import ac5 from '../media/photos/aria_condizionata_5.jpeg';
import floor1 from '../media/photos/pavimento_1.jpeg';
import floor2 from '../media/photos/pavimento_2_cropped.jpeg';
import floor3 from '../media/photos/pavimento_3.jpeg';
import painting1 from '../media/photos/imbiancatura_1.jpeg';


export default function PhotoGallery() {
    const { t } = useTranslation(namespaces.common);

    return (
        <div style={{ display: "contents" }} >
            <H2Container id={t("nav/completed-projects")}>
                <H2>{t("Progetti completati")}</H2>
            </H2Container>

            <Card
                title={t("Bagni")}
                subtitle={t("Prima... Durante... Dopo")}
                id={t("nav/bath")}
            >
                <ImageContainer>
                    <LazyImage src={washroomBefore} alt="progetto-1" />
                    <LazyImage src={washroomAfter} alt="progetto-1" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={showerBefore} alt="progetto-2" />
                    <LazyImage src={showerAfter} alt="progetto-2" />
                </ImageContainer>
            </Card>

            <Card
                title={t("Infissi e porte")}
                subtitle=""
                id={t("nav/doors")}
            >
                <ImageContainer>
                    <LazyImage src={windowsDoors2} alt="infissi-e-porte-2" />
                    <LazyImage src={windowsDoors3} alt="infissi-e-porte-3" />
                </ImageContainer>

                <ImageContainer>
                    {/* Lazyimage spans over two columns because horizontal */}
                    <LazyImage src={windowsDoors1} alt="infissi-e-porte-1" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>

            <Card
                title={t("Imbiancatura lavabile")}
                subtitle=""
                id={t("nav/painting")}
            >
                <ImageContainer>
                    <LazyImage src={painting1} alt="imbiancatura-lavabile-1" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>


            <Card
                title={t("Soluzioni industriali")}
                subtitle={t("Cucina")}
            >
                <ImageContainer>
                    <LazyImage src={industry2} alt="soluzioni-industriali-2" />
                    <LazyImage src={industry3} alt="soluzioni-industriali-3" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={industry1} alt="soluzioni-industriali-1" />
                    <LazyImage src={industry5} alt="soluzioni-industriali-5" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={industry4} alt="soluzioni-industriali-4" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>

            </Card>

            <Card
                title={t("Soluzioni industriali")}
                subtitle={t("Uffici con pannelli divisori in vetro")}
                id={t("nav/office")}
            >
                <ImageContainer>
                    <LazyImage src={industry7} alt="soluzioni-industriali-7" />
                    <LazyImage src={industry11} alt="soluzioni-industriali-11" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={industry12} alt="soluzioni-industriali-12" />
                    <LazyImage src={industry8} alt="soluzioni-industriali-8" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={industry9} alt="soluzioni-industriali-9" />
                    <LazyImage src={industry10} alt="soluzioni-industriali-10" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={industry6} alt="soluzioni-industriali-6" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>

            <Card
                title={t("Soluzioni su misura")}
                subtitle={t("Apporto di muri in c/gesso ed illuminazione per ogni ambiente")}
                id={t("nav/drywall")}
            >
                <ImageContainer>
                    <LazyImage src={lights3} alt="su-misura-5" />
                    <LazyImage src={lights2} alt="su-misura-4" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={lights6} alt="su-misura-8" />
                    <LazyImage src={lights5} alt="su-misura-7" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={lights1} alt="su-misura-3" />
                    <LazyImage src={lights4} alt="su-misura-6" />
                </ImageContainer>

                <ImageContainer>
                    {/* <LazyImage src={drywall1} alt="su-misura-1" /> */}
                    <LazyImage src={drywall2} alt="su-misura-2" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>


            <Card
                title={t("Soluzioni su misura")}
                subtitle={t("Ridimensinamento spazi")}
            >
                <ImageContainer>
                    <LazyImage src={spaceRedimensioning1} alt="su-misura-9" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={spaceRedimensioning2} alt="su-misura-10" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>

            <Card
                title={t("Nuovo sistema di condizionamento")}
                subtitle={t("con detrazioni fiscali")}
                id={t("nav/air-conditioning")}
            >
                <ImageContainer>
                    <LazyImage src={ac1} alt="aria-condizionata-1" />
                    <LazyImage src={ac2} alt="aria-condizionata-2" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={ac3} alt="aria-condizionata-3" />
                    <LazyImage src={ac4} alt="aria-condizionata-4" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={ac5} alt="aria-condizionata-5" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>


            <Card
                title={t("Posa pavimenti")}
                subtitle=""
                id={t("nav/floor")}
            >
                <ImageContainer>
                    <LazyImage src={floor1} alt="pavimento-1" />
                    <LazyImage src={floor3} alt="pavimento-3" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={floor2} alt="pavimento-2" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>

            <Card
                title={t("Cartongesso per isolamento")}
                subtitle=""
            >
                <ImageContainer>
                    <LazyImage src={drywall3} alt="cartongesso-isolamento-1" />
                    <LazyImage src={drywall4} alt="cartongesso-isolamento-2" />
                </ImageContainer>

                <ImageContainer>
                    <LazyImage src={drywall5} alt="cartongesso-isolamento-3" />
                    <div style={{ flex: "1 2 100px" }}></div>
                </ImageContainer>
            </Card>

            <Card
                title={t("Cartongesso esterni")}
                subtitle={t("Prima e dopo")}
                id={t("nav/house")}
            >
                <ImageContainer>
                    <LazyImage src={drywallOutsideBefore} alt="cartongesso-esterni-1" />
                    <LazyImage src={drywallOutsideAfter} alt="cartongesso-esterni-2" />
                </ImageContainer>
            </Card>
        </div>
    )
}

const H2Container = styled.div`
  grid-column: 3/-3;
`;