import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { namespaces } from "./i18n.costants";

/**
 * addPath and the feature saveMissing will only work with a "real" server
 * able to post data to an endpoint that handles that data
 * see https://github.com/i18next/i18next-http-middleware
 *
 * current version 22.4.14 of i18next does not return a string anymore
 * to fix the issue see https://github.com/i18next/react-i18next/issues/1587#issuecomment-1386909661
 */
declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18next
    .use(initReactI18next)
    .use(HttpApi) // Use backend plugin for translation file download.
    .use(LanguageDetector) // Use the detection plugin to detect user language
    .init({
        //     backend: {
        //         loadPath: "public/locales/{{lng}}/{{ns}}.json", // Specify where backend will find translation files.
        //     },
        fallbackLng: "it",
        detection: {
            convertDetectedLanguage: 'Iso15897',
        },
        ns: namespaces.common,
        returnNull: false,
    });

export default i18next;