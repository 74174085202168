import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components/macro";
import FocusTrap from "focus-trap-react";
import useClickOutside from "./UseClickOutside";

type BurgerMenuProps = {
    children: React.ReactNode,
    initialFocus: string,
};

export default function BurgerMenu({ children, initialFocus }: BurgerMenuProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const close = useCallback(() => setIsOpen(false), [])
    const open = useCallback(() => setIsOpen(true), [])
    const toggleOpen = useCallback(() => isOpen ? close() : open(), [isOpen])
    useClickOutside(containerRef, close, isOpen);

    return (
        <Wrapper ref={containerRef}>
            <NavContainer>
                <HamburgerLines onClick={toggleOpen}>
                    <LineOne $isOpen={isOpen} />
                    <LineTwo $isOpen={isOpen} />
                    <LineThree $isOpen={isOpen} />
                </HamburgerLines>
                {isOpen && <FocusTrap

                    focusTrapOptions={{
                        clickOutsideDeactivates: true,
                        initialFocus: initialFocus,
                    }}>
                    <MenuItems $isOpen={isOpen} >
                        {children}
                    </MenuItems>
                </FocusTrap>}
            </NavContainer>
        </Wrapper >
    )
}

const Wrapper = styled.div`
    display: none;
    background: none;
	border: none;
	padding: 0;
    @media (max-width: 992px) {
        align-self: center;
        justify-self: center;
        display: block;
    }
`;
const NavContainer = styled.div`
    max-width: 1050px;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: block;
    position: relative;
`;
const HamburgerLines = styled.button`
    padding: 2px;
    cursor: pointer;
    height: 28px;
    width: 34px;
    border: none;
    background: none;
    align-self: end;
    justify-self: center;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
interface ILine {
    $isOpen: boolean;
}
const Line = styled.span<ILine>`
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
`;
const LineOne = styled(Line)`
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
    transform: ${props => props.$isOpen ? 'rotate(45deg)' : 'none'};
    margin-left: ${props => props.$isOpen ? "6px" : "0"};
`;
const LineTwo = styled(Line)`
    transition: transform 0.2s ease-in-out;
    transform: ${props => props.$isOpen ? 'scaleY(0)' : 'none'};
`;
const LineThree = styled(Line)`
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
    transform: ${props => props.$isOpen ? 'rotate(-45deg)' : 'none'};
    margin-left: ${props => props.$isOpen ? "6px" : "0"};
`;

const MenuItems = styled.div<ILine>`
    position: absolute;
    width: 200px;
    background-color: white;
    top: calc(100% + 20px);
    right: 0;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    transform: ${props => props.$isOpen ? ' translateX(0)' : 'none'};
    box-shadow: 3px 5px 15px rgba(0,0,0,0.2);
`;


