import { forwardRef, ReactElement } from "react";
import styled from "styled-components/macro";

type CardProps = {
    children: React.ReactNode;
    title: string | ReactElement;
    subtitle?: string;
    textColor?: string;
    id?: string;
    [rest: string]: any;
};

const Card = forwardRef<any, CardProps>(({ children, title, subtitle, textColor = "var(--color-blue)", id, ...rest }, ref) => {
    return (
        <Wrapper {...rest}>
            <StickyContainer>
                <Title $textColor={textColor}>{title}</Title>
                {subtitle && <SubtitleContainer>
                    <Subtitle $textColor={textColor}>- {subtitle}</Subtitle>
                </SubtitleContainer>
                }
            </StickyContainer>
            <ContentContainer ref={ref} id={id}>
                {children}
            </ContentContainer>
        </Wrapper>
    )
});

export default Card;

interface ICardWrapper {
    // backgroundColor?: string;
}
const Wrapper = styled.div<ICardWrapper>`
    grid-column: 1 / -1;
    min-height: 200px;
    padding: 20px;
    display: contents;
    gap: 20px;
`;
const ContentContainer = styled.div`
    grid-column: 3 / -3;
    padding: 1em 0;
    text-align: justify;
    scroll-margin-top: 7em;
`;
const StickyContainer = styled.div`
    position: sticky;
    top: 110px;
    grid-column: 1 / 3;
    height: 180px;
    background-color: var(--color-background);
    height: 100%;

    @media (max-width: 800px) {
        grid-column: 3 / -3;
        height: min-content;
        position: initial;
        display: inline;
        background-color: transparent;
    }
`;
interface ITitle {
    $textColor: string;
}
const Title = styled.h2<ITitle>`
    font-family: Poppins, Arial, sans-serif;
    padding: 20px 0;
    color: ${props => props.$textColor};
    margin-left: 20px;
    background-color: var(--color-background);
    font-size: clamp(0.8rem, -3rem + 9vw, 1rem);
    word-wrap: anywhere;

    @media (max-width: 800px) {
        padding: 0;
        margin-left: 0;
        background-color: transparent;
        display: inline;
    }
`;
const Subtitle = styled.h3<ITitle>`
    font-family: Poppins, Arial, sans-serif;
    padding: 20px 0;
    color: ${props => props.$textColor};
    background-color: var(--color-background);
    font-size: clamp(0.8rem, -3rem + 9vw, 1rem);
    word-wrap: anywhere;

    @media (max-width: 800px) {
        background-color: transparent;
        display: inline;
        &::before {
            content: " ";
        }
    }
`;
const SubtitleContainer = styled.div`
    display: flex;
    margin-left: 40px;
    align-items: baseline;

    @media (max-width: 800px) {
        margin-left: 0;
        display: inline;
    }
`;
