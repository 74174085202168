import Button from './Button';
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";

type QuoteButtonProps = {
    handleClick?: () => void;
};
export default function QuoteRequestButton({ handleClick }: QuoteButtonProps) {
    const { t } = useTranslation(namespaces.common);

    return (
        <ButtonContainer>
            <Button
                text={t("Richiedi un preventivo adesso")}
                textColor='white'
                backgroundColor='var(--color-blue-gradient)'
                callToAction={true}
                handleClick={handleClick}
                style={{ width: "100%", display: "block" }}
            />
        </ButtonContainer>
    )
}


const ButtonContainer = styled.div`
    /* margin: auto; */
    /* max-width: max-content; */
    width: 100%;
`;