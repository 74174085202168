import { useCallback } from "react";
import styled from "styled-components/macro";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { languages, namespaces } from "../i18n.costants";

export default function LanguageSelection() {
    const { i18n } = useTranslation(namespaces.common);
    const language = i18n.language.split('_', 1)[0]

    const handleChange = useCallback((language: string) => {
        i18n.changeLanguage(language);
    }, [i18n]);

    return (
        <>
            {Object.keys(languages).map((lang) =>

                <LanguageOption key={lang}>
                    <Button
                        underlineOnHover={true}
                        handleClick={() => handleChange(lang)}
                        text={lang.toUpperCase()}
                        selected={language === lang}
                    />
                </LanguageOption>
            )}
        </>
    )
};

const LanguageOption = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        border-right: 1px solid hsl(227deg 70% 5%);
    }
`;