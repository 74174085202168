import styled from "styled-components/macro";

export const GeneralText = styled.div`
    font-family: Poppins, Arial, sans-serif;
    font-size: 1em;
    word-wrap: break-word;
`;
export const StyledHeader = styled.p`
    font-family: Poppins, Arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-text);
    font-size: clamp(1rem,-2.3149rem + 8.8398vw,1.5rem);
    font-weight: 600;
    grid-column: span 2;
    padding-top: 10px;
`;