import styled from 'styled-components/macro';
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";
import Card from './Card';
import { GeneralText } from './GeneralText';
import { ReactComponent as AriaCondizionata } from '../media/aria_condizionata.svg';
import { ReactComponent as Cartongesso } from '../media/cartongesso.svg';
import { ReactComponent as Elettricisti } from '../media/elettricisti.svg';
import { ReactComponent as Finestre } from '../media/finestre_e_porte_finestre.svg';
import { ReactComponent as Zanzariere } from '../media/zanzariere.svg';
import { ReactComponent as Imbianchini } from '../media/imbiancare.svg';
import { ReactComponent as Muratori } from '../media/muratori.svg';
import { ReactComponent as Porte } from '../media/porte.svg';
import { ReactComponent as Pavimenti } from '../media/posa_pavimenti.svg';
import { ReactComponent as Bagni } from '../media/ristrutturazione_bagni.svg';
import { ReactComponent as Casa } from '../media/ristrutturazione_casa.svg';
import { ReactComponent as Cucina } from '../media/ristrutturazione_cucina.svg';
import { ReactComponent as Uffici } from '../media/ristrutturazione_uffici.svg';
import { ReactComponent as Macerie } from '../media/smaltimento_macerie.svg';


const icons = [
  { name: "Aria condizionata", icon: <AriaCondizionata />, href: "nav/air-conditioning" },
  { name: "Cartongesso", icon: <Cartongesso />, href: "nav/drywall" },
  { name: "Elettricisti", icon: <Elettricisti />, href: "" },
  { name: "Finestre e porte finestre", icon: <Finestre />, href: "" },
  { name: "Zanzariere", icon: <Zanzariere />, href: "" },
  { name: "Imbianchini", icon: <Imbianchini />, href: "nav/painting" },
  { name: "Muratori", icon: <Muratori />, href: "" },
  { name: "Porte", icon: <Porte />, href: "nav/doors" },
  { name: "Posa pavimenti", icon: <Pavimenti />, href: "nav/floor" },
  { name: "Ristrutturazione bagni", icon: <Bagni />, href: "nav/bath" },
  { name: "Ristrutturazione casa", icon: <Casa />, href: "nav/house" },
  { name: "Ristrutturazione cucina", icon: <Cucina />, href: "" },
  { name: "Ristrutturazione uffici", icon: <Uffici />, href: "nav/office" },
  { name: "Smaltimento macerie", icon: <Macerie />, href: "" },
]

export default function IconsCard() {
  const { t } = useTranslation(namespaces.common);

  return (
    <Card
      title={t("Servizi offerti")}
      subtitle=""
      id={t("nav/services")}
    >
      <FlexContainer>
        {
          icons.map((item, i) => <IconContainer
            key={i}
            as={item.href ? "a" : "div"}
            href={`#${t(item.href)}`}
          >
            {item.icon}
            <SmallText>{t(item.name)}</SmallText>
          </IconContainer>)
        }
      </FlexContainer>
    </Card>
  )
}

const FlexContainer = styled.div`
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: 1fr;
  align-items: baseline;
  justify-items: center;

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  }
`;
const IconContainer = styled.a`
  width: 100px;
  height: 100%;
  text-align: center;
  background: none;
  text-decoration: none;
  color: black;

  @media (max-width: 450px) {
    width: 60px;
  }
`;
const SmallText = styled(GeneralText)`
  font-size: 0.9rem;
  display: revert;

  @media (max-width: 450px) {
    display: none;
  }
`;