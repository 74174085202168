import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useClickOutside(ref: any, action: any, isOpen: boolean) {
    useEffect(() => {
        if (!isOpen) return
        function handleClickOutside(event: any) {
            if (!document.body.contains(event.target)) return
            if (ref.current && !ref.current.contains(event.target)) {
                action()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isOpen]);
}