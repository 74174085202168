import Card from './Card';
import { GeneralText } from './GeneralText';
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";
import styled from 'styled-components/macro';

export default function AboutUsCard() {
  const { t } = useTranslation(namespaces.common);

  return (
    <Card title={t("Chi siamo")} id={t("nav/about-us")} >
      <CardText>
        {t(`__Descrizione_chi_siamo__`)}
      </CardText>
    </Card>
  )
}


const CardText = styled(GeneralText)`
  /* text-indent: 2em; */
  &:first-of-type:first-letter {
    font-family: Poppins, Arial, sans-serif;
    font-weight: 600;
    font-size: 3em;
    float: left;
    line-height: 1em;
    margin-right: 0.2em;
    color: hsl(209deg 75% 14%);
  }

  @media (max-width: 500px) {
    text-align: start;
  }
`;