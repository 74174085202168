import styled from 'styled-components/macro';
import { LazyLoadImage } from "react-lazy-load-image-component";

type LazyImageProps = {
  src: string;
  placeholderImage?: string;
  alt?: string;
}
export default function LazyImage({ src, placeholderImage, alt }: LazyImageProps) {
  return (
    <LazyLoadImage
      src={src}
      style={{ width: "100%", flex: "1 2 100px", minWidth: "100px", display: "block", maxWidth: "100%" }}
      // PlaceholderSrc={placeholderImage}
      // effect="blur"
      alt={alt}
    />
  )
}

interface IContainer {
  noMargin?: boolean
}
export const ImageContainer = styled.div<IContainer>`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  grid-column: 3/-3;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  margin-bottom: ${props => props.noMargin ? "0" : "var(--gap)"};

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
export const Image = styled.img`
  width: 100%;
  flex: 1 2 100px;
  min-width: 100px;
  /* max-width: 280px; */
`;