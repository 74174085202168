import { ReactComponent as Logo } from '../media/logo-icon.svg';
import styled from "styled-components/macro";
import { Anchor } from './Button';
import BurgerMenu from './BurgerMenu';
import LanguageSelection from './LanguageSelection';
import { useTranslation, Trans } from "react-i18next";
import { namespaces } from "../i18n.costants";


export default function Header() {
    const { t } = useTranslation(namespaces.common);

    return (
        <Wrapper>
            <LogoAndText>
                <LogoContainer href={`#${t("nav/home")}`}>
                    <Logo fill="var(--color-blue-dark)" color="var(--color-blue-dark)" />
                </LogoContainer>
                <Title>V&V SERVIZI</Title>
                <Subtitle>{t("FORNITURE • MONTAGGI • MANUTENZIONI")}</Subtitle>
            </LogoAndText>

            <NavBar>
                <NavItem><Anchor href={`#${t("nav/home")}`} underlineOnHover={true} text={t("Home")} /></NavItem>
                <NavItem><Anchor href={`#${t("nav/about-us")}`} underlineOnHover={true} text={t("Chi siamo")} /></NavItem>
                <NavItem><Anchor href={`#${t("nav/completed-projects")}`} underlineOnHover={true} text={<Trans shouldUnescape={true}>Progetti completati</Trans>} /></NavItem>
                <NavItem><LanguageSelection /></NavItem>
                <NavItem><Anchor
                    href={`#${t("nav/contact")}`}
                    underlineOnHover={true}
                    textColor="white"
                    backgroundColor='var(--color-blue-gradient)'
                    text={t("Contattaci")}
                /></NavItem>
            </NavBar>

            <BurgerMenu initialFocus="#home-button">
                <Anchor href={`#${t("nav/home")}`} underlineOnHover={true} text={t("Home")} id="home-button" />
                <Anchor href={`#${t("nav/about-us")}`} underlineOnHover={true} text={t("Chi siamo")} />
                <Anchor href={`#${t("nav/services")}`} underlineOnHover={true} text={t("Servizi")} />
                <Anchor href={`#${t("nav/completed-projects")}`} underlineOnHover={true} text={t("Progetti completati")} />
                <ButtonRow>
                    <LanguageSelection />
                </ButtonRow>
                <Anchor href={`#${t("nav/contact")}`} underlineOnHover={true} text={t("Contattaci")} />
            </BurgerMenu>
        </Wrapper>
    )
}

const Wrapper = styled.header`
    grid-column: 1 / -1;
    background-color: var(--color-gray-light);
    padding: 5px var(--gap);
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    gap: var(--gap);
    height: 68px;
`;
const NavBar = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    @media (max-width: 992px) {
        display: none;
    } 
`;
const NavItem = styled.li`
    display: flex;
    align-items: stretch;
`;
const LogoContainer = styled.a`
    width: 90px;
    grid-column: 1;
    grid-row: span 2;
    margin-right: 10px;
    margin-bottom: 5px;
`;
const LogoAndText = styled.div`
    font-family: Poppins, Arial, sans-serif;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, auto);
    color: var(--color-text);
`;
const Title = styled.div`
    font-size: 1rem;
    align-self: end;
    font-weight: 600;
    @media (max-width: 500px) {
        display: none;
    }
`;
const Subtitle = styled.div`
    line-height: 0.8rem;
    font-size: 0.8rem;
    align-self: baseline;
    @media (max-width: 500px) {
        display: none;
    }
`;
const ButtonRow = styled.div`
    display: flex;
    align-self: center;
`;