import styled from 'styled-components/macro';
import { ReactComponent as Phone } from '../media/phone-call-icon.svg';
import { ReactComponent as Mail } from '../media/mail-icon.svg';
import QuoteRequestButton from './QuoteRequestButton';
import Card from './Card';
import { useTranslation } from "react-i18next";
import { namespaces } from "../i18n.costants";


type ContactCardProps = {
  handleClick?: () => void;
};
export default function ContactCard({ handleClick }: ContactCardProps) {
  const { t } = useTranslation(namespaces.common);

  return (
    <Card title={t("Contattaci")} id={t("nav/contact")}>
      <FormContainer>
        <Form>
          <ContactIconContainer>
            <Phone fill="var(--color-blue)" />
          </ContactIconContainer>
          <Contact>Victor: <Link href="tel:+393280368221">+39&nbsp;328&nbsp;0368221</Link></Contact>
          <Contact>Valentin: <Link href="tel:+393898836147">+39&nbsp;389&nbsp;8836147</Link></Contact>
        </Form>
        <Form>
          <ContactIconContainer>
            <Mail fill="var(--color-blue)" />
          </ContactIconContainer>
          <Contact>{t("Avete domande?")}</Contact>
          <Contact>{t("Non esitate a contattarci")}</Contact>
          <Contact><Link href="mailto:vevservizisnc@gmail.com">vevservizisnc@gmail.com</Link></Contact>
        </Form>
      </FormContainer>
      <QuoteRequestButton handleClick={handleClick} />
    </Card>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--gap);

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const Form = styled.form`
  margin: var(--gap) 0;
  background: white;
  grid-column: span 4;
  padding: var(--gap);
  padding-top: 150px;
  position: relative;
  flex: 1 1;
`;
const ContactIconContainer = styled.div`
  position: absolute;
  top: -20px;
  left: calc(50% - 65px);
  margin: auto;
  width: 130px;
  height: 130px;
  padding: var(--gap);
  border-radius: 50%;
  background-color: var(--color-gray-light);
  display: flex;
  justify-content: center;
  box-shadow: 3px 5px 15px rgba(0,0,0,0.2);
`;
const Contact = styled.div`
  font-family: Poppins, Arial, sans-serif;
  text-align: center;
  font-size: 1.2em;
  word-wrap: anywhere;
`;
const Link = styled.a`
  color: var(--color-blue);
`;