import Victor from '../media/photos/Victor.jpeg';
import Valentin from '../media/photos/Valentin.jpeg';
import { useTranslation, Trans } from "react-i18next";
import { namespaces } from "../i18n.costants";
import LazyImage, { ImageContainer } from './ImageContainer';
import { GeneralText } from './GeneralText';
import styled from 'styled-components/macro';
import QuoteRequestButton from './QuoteRequestButton';


type HomeProps = {
    handleClick?: () => void;
};
export default function Home({ handleClick }: HomeProps) {
    const { t } = useTranslation(namespaces.common);

    return (
        <Wrapper id={t("nav/home")}>
            <Statement>
                <Trans i18nKey="__Statement__">
                    Realizza la tua visione e <SpecialFont> trasforma </SpecialFont> la tua casa o il tuo ufficio in un luogo <SpecialFont> speciale </SpecialFont>
                </Trans>
            </Statement>
            <ImageContainer1 noMargin={true}>
                <LazyImage src={Victor} alt="Victor" />
            </ImageContainer1>
            <ImageContainer2 noMargin={true}>
                <LazyImage src={Valentin} alt="Valentin" />
            </ImageContainer2>
            <Line1>
                <Cursive>{t("Perché noi?")}</Cursive> {t("Anni di esperienza e dedizione alla qualità, all'innovazione e alla cura dei dettagli.")}
            </Line1>
            <Line2>
                <Cursive>{t("La nostra offerta?")}</Cursive> {t("Trasformazioni personalizzate per case e uffici.")}
            </Line2>
            <Line3>
                <Cursive>{t("Il risultato?")}</Cursive> {t("Spazi ispiratori e funzionali che ti sorprenderanno.")}
            </Line3>
            <ButtonContainer>
                <QuoteRequestButton handleClick={handleClick} />
            </ButtonContainer>
        </Wrapper >
    )
}

const Wrapper = styled.div`
    grid-column: 3 / -3;
    margin-bottom: 3em;
    scroll-margin-top: 4em;
    min-height: calc( 100vh - 68px);
    display: grid;
    gap: 20px;
    align-content: space-evenly;
    grid-template-rows: minmax(auto, 1fr) repeat(3, 1fr) minmax(auto, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        "h h h "
        "p1 p2 t1"
        "p1 p2 t2"
        "p1 p2 t3"
        "b b b";

    @media (max-width: 1100px) {
        margin-top: 1em;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "h h"
            "p1 p2"
            "b b"
            "t1 t1"
            "t2 t2"
            "t3 t3";
    }
`;
const Statement = styled.div`
    font-family: Italiana, Arial, sans-serif;
    font-size: 1rem;
    font-size: clamp(1rem, -2.3149rem + 8.8398vw, 1.5rem);
    font-weight: 800;
    text-transform: uppercase;
    color: var(--color-text);
    word-wrap: anywhere;
    text-align: center;
    grid-area: h;
    align-self: center;
    vertical-align: middle;
    display: inline-block;
    margin: -1em 0;
    line-height: 1.4em;

    @media (max-width: 1200px) {
        padding: 0.5em;
        margin: 0 0.5em;
    }
`;
const SpecialFont = styled.div`
    font-family: 'Arizonia', cursive;
    font-size: 3.5rem;
    font-size: clamp(3rem, -2.3149rem + 8.8398vw, 4rem);
    text-transform: none;
    display: inline;
`;
const Cursive = styled.p`
    font-family: 'Rochester', cursive;
    color: var(--color-text);
    font-size: clamp(1.5rem, -2.3149rem + 8.8398vw, 2rem);
    font-weight: bold;
`;
const ImageContainer1 = styled(ImageContainer)`
    grid-area: p1;
`;
const ImageContainer2 = styled(ImageContainer)`
    grid-area: p2;
`;
const Line = styled(GeneralText)`
    @media (max-width: 1200px) {
        text-align: center;
    }
`;
const Line1 = styled(Line)`
    grid-area: t1;
`;
const Line2 = styled(Line)`
    grid-area: t2;
`;
const Line3 = styled(Line)`
    grid-area: t3;
`;
const ButtonContainer = styled.div`
    grid-area: b;
    align-self: center;
`;